@tailwind base;
@tailwind components;
@tailwind utilities;
@import "~@flaticon/flaticon-uicons/css/all/all";

@font-face {
  font-family: "Outfit";
  src: url("../public/fonts/Outfit/Outfit-Regular.ttf");
}
@font-face {
  font-family: "Outfit Black";
  src: url("../public/fonts/Outfit/Outfit-Black.ttf");
}
@font-face {
  font-family: "Outfit Bold";
  src: url("../public/fonts/Outfit/Outfit-Bold.ttf");
}
@font-face {
  font-family: "Outfit ExtraBold";
  src: url("../public/fonts/Outfit/Outfit-ExtraBold.ttf");
}
@font-face {
  font-family: "Outfit ExtraLight";
  src: url("../public/fonts/Outfit/Outfit-ExtraLight.ttf");
}
@font-face {
  font-family: "Outfit Light";
  src: url("../public/fonts/Outfit/Outfit-Light.ttf");
}
@font-face {
  font-family: "Outfit Medium";
  src: url("../public/fonts/Outfit/Outfit-Medium.ttf");
}
@font-face {
  font-family: "Outfit Regular";
  src: url("../public/fonts/Outfit/Outfit-Regular.ttf");
}
@font-face {
  font-family: "Outfit SemiBold";
  src: url("../public/fonts/Outfit/Outfit-SemiBold.ttf");
}
@font-face {
  font-family: "Outfit Thin";
  src: url("../public/fonts/Outfit/Outfit-Thin.ttf");
}
/* 
[data-tooltip-target]:hover::after {
  content: attr(data-tooltip-content);
  display: block;
  position: absolute;
  z-index: 50;
  background-color: white;
  padding: 0.375rem 0.75rem;
  border-radius: 0.5rem;
  font-family: sans-serif;
  font-size: 0.875rem;
  font-weight: normal;
  white-space: normal;
  top: 100%; 
  left: 50%; 
  transform: translateX(-50%);
  margin-top: 0.5rem;
}

[data-tooltip-target] {
  position: relative;
} */
